import React from "react";
import Layout from "../components/Layout";
import { NotFoundSection } from "../sections";

const NotFoundPage = () => {
  return (
    <Layout title="ChatIQ | Page Not Found">
      <NotFoundSection />
    </Layout>
  );
};

export default NotFoundPage;
